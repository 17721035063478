import { ITheme } from "@fluentui/react";
import { User } from "oidc-react";
import * as React from "react";
import { Navigate, Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { AppLayout } from "./AppLayout";
import { Authenticated } from "./Authenticated";
import { AuthService } from "./components/AuthService";
import { Error401 } from "./Error401";
import { LandingPage } from "./landing";
import { SignInFlowRedirect } from "./SignInFlowRedirect";

interface IAppRouteProps {
  theme: ITheme;
  onThemeChanged: (theme: string) => void;
}

export const AppRouter: React.FunctionComponent<IAppRouteProps> = ({
  theme,
  onThemeChanged,
}) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/mtls" element={<SignInFlowRedirect name="B2C_1A_ClientCertificate" />} />
        <Route path="/signin-error" element={<Error401 />} />
        <Route path="/signup-error" element={<Error401 />} />
        <Route path="/access-denied" element={<Error401 />} />
        <Route
          path="/welcome"
          element={
            <Authenticated isSignup={true} onThemeChanged={onThemeChanged} theme={theme}>
              {(user: User /*, authService: AuthService, api: Client */) => (
                <Routes>
                  <Route path="/signout-oidc" element={<div />} />
                  <Route
                    path="/*"
                    element={<LandingPage user={user} />}
                  />
                </Routes>
              )}
            </Authenticated>
          }
        />
        <Route
          path="/*"
          element={
            <Authenticated onThemeChanged={onThemeChanged} theme={theme}>
              {(user: User, authService: AuthService /* , api: Client */) => (
                <AppLayout authService={authService} theme={theme} user={user}>
                  <div>
                    <Routes>
                      <Route path="/signout-oidc" element={<div />} />
                      <Route
                        path="/"
                        element={
                          <LandingPage user={user} />
                        }
                      />
                      <Route
                        path="/*"
                        element={
                          <Navigate to="/" />
                        }
                      />
                    </Routes>
                  </div>
                </AppLayout>
              )}
            </Authenticated>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
