/* eslint-disable @typescript-eslint/explicit-function-return-type */

export const meta = (name: string) => {
  const element = document.querySelectorAll(`meta[name=${name}]`)[0];
  return element?.getAttribute("content") ?? "";
};

export const metaExists = (name: string) => {
  const element = document.querySelectorAll(`meta[name=${name}]`)[0];
  return element !== undefined && element !== null;
};

function isValidHttpUrl(value: string) {
  let url;

  try {
    url = new URL(value);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export const ServiceUris = {
  authority: () => meta("WWW-authenticate"),
  cdn: () => meta("WWW-cdn-uri"),
  account: () => meta("WWW-account"),
  admin: () => meta("WWW-admin"),
  home: () => meta("WWW-home"),
  locale: {
    base: () =>
      metaExists("WWW-i18n-base")
        ? isValidHttpUrl(meta("WWW-i18n-base"))
          ? meta("WWW-i18n-base")
          : `${cdn()}/locales`
        : `${cdn()}/locales`,
    report: () => meta("WWW-i18n-report"),
    ui: () =>
      metaExists("WWW-i18n-locale-picker-uri")
        ? meta("WWW-i18n-locale-picker-uri")
        : `${cdn()}/localepicker`,
  },
  feedback: () =>
    metaExists("WWW-feedback-uri")
      ? meta("WWW-feedback-uri")
      : `${cdn()}/webcomponents/feedback-form`,
};

export const cdn = () => ServiceUris.cdn();

export const getContainerStyleBasedOnResolution = () => {
  const screenWidth = window.screen.width;
  if (screenWidth < 1600) {
    return {
      panelWidth: "85%",
      navWidth: "25vw",
      contentWidth: "65vw",
    };
  } else {
    return {
      panelWidth: "62%",
      navWidth: "30vw",
      contentWidth: "70vw",
    };
  }
};

export const delay = async (ms?: number) =>
  await new Promise<void>((resolve) => setTimeout(() => resolve, ms ?? 2000));

export const mock = delay;

export const withQueryString = (uri: string, key: string, value: string) => {
  const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  const separator = uri.includes("?") ? "&" : "?";
  if (uri.match(re) != null) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
};

const copyToClipboardFallback = (val: string) => {
  const selBox = document.createElement("textarea");
  selBox.style.position = "fixed";
  selBox.style.left = "0";
  selBox.style.top = "0";
  selBox.style.opacity = "0";
  selBox.value = val;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand("copy");
  document.body.removeChild(selBox);
};

export const copyToClipboard = (text: string) => {
  if (navigator.clipboard === undefined || navigator.clipboard == null) {
    copyToClipboardFallback(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () { },
    function (err) {
      console.error("Could not copy text: ", err);
    },
  );
};

