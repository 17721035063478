import React from "react"
import { AuthService } from "./components/AuthService";
import { OidcSettings } from "./components/OidcSettings";
import { ServiceUris } from "./components/utils";

const getQueryParamValue = (paramName: string): string => {
    const searchParams = new URLSearchParams(new URL(location.href).search);
    return searchParams.get(paramName) ?? "";
};

export const SignInFlowRedirect = ({ name }: {
    name: string
}) => {
    React.useEffect(() => {
        new AuthService({
            ...OidcSettings,
            authority: ServiceUris.authority().replace("B2C_1A_SignIn", name),
            extraQueryParams: {
                id_token_hint: getQueryParamValue("id_token_hint")
            }
        }).login();
    }, []);
    return <div>
    </div>
}