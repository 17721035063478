
import { ITheme, Link, Text } from "@fluentui/react";
import { TFunction } from "i18next";
import { User } from "oidc-react"
import React from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate } from "react-router";
import { AuthService } from "./components/AuthService";
import { IHeaderProps, MainLayout } from "./components/Layouts";

interface IAppLayoutProps {
    theme?: ITheme;
    user: User;
    authService: AuthService;
    t?: TFunction;
    headerProps?: IHeaderProps;
    navigate?: NavigateFunction;
    children?: any;
}

export const AppLayout: React.FunctionComponent<IAppLayoutProps> = (
    { authService, user, theme, children, navigate = useNavigate(), t = useTranslation().t, headerProps = {
        appBarProps: {
            title: "CGCD",
            user: user,
            onNavigate: (value) => navigate(value),
            onSignout: () => {
                authService.logout();
            }
        }
    } }
) => <MainLayout theme={theme} headerProps={headerProps} footerProps={{
    sticky: false,
    children: <div>
        <div style={{ textAlign: "center", width: "100%" }}>
            <ul style={{ padding: "0px" }}>
                <li><Link target="_blank" href="https://www.cyber.gouv.qc.ca/vulnerabilite/confidentialite.php">
                    <Text>
                        {t("Application.Footer.Privacy", "Politique de confidentialité")}
                    </Text>
                </Link></li>
            </ul>
            <img src="/images/logo.png" height="42" alt="Ministère de la Cybersécurité et du Numérique" style={{ height: "42px" }} />
            <br />
            <Link target="_blank" href="http://www.droitauteur.gouv.qc.ca/copyright.php"><Text>
                {t("Application.Footer.Copyrights", "© Gouvernement du Québec, 2023")}</Text></Link>
        </div>
    </div>
}}>
        {children}
    </MainLayout >