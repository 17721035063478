/* eslint-disable @typescript-eslint/consistent-type-assertions */
import {
  Callout,
  CommandBar,
  CommandBarButton,
  DirectionalHint,
  IButtonProps,
  ICalloutProps,
  ICommandBarItemProps,
  Icon,
  IIconProps,
  INavLinkGroup,
  ISearchBoxProps,
  ITheme,
  SearchBox,
  Stack,
  StackItem,
  Text
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { User } from "oidc-react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { trigger } from "../events";
import { IdentityUser } from "../IdentityUser";
import { useMediaQueries } from "../MediaQueries";
import { Theming } from "../Theming";
import { metaExists } from "../utils";
import { MobileSearch } from "./MobileSearch";
import { NavigationPanel } from "./NavigationPanel";
import { UserPanel } from "./UserPanel";

export interface IAppBarProps {
  theme?: ITheme;
  accountIconProps?: IIconProps;
  globalMenuOnly?: boolean;
  searchProps?: ISearchBoxProps;
  searchCalloutProps?: ICalloutProps;
  commands?: ICommandBarItemProps[];
  feedbackProps?: ICommandBarItemProps;
  backgroundColor?: string;
  invertedBackgroundColor?: string;
  color?: string;
  invertedColor?: string;
  nav?: INavLinkGroup;
  appNav?: INavLinkGroup[];
  areaNav?: INavLinkGroup[];
  onNavigate: (value: string) => void;
  title?: string | JSX.Element;
  user?: User;
  onSignout?: () => void;
  onUserChangeRequested?: (id: string, action?: string) => void;
  children?: any;
}

export const AppBar: React.FunctionComponent<IAppBarProps> = ({
  theme,
  globalMenuOnly,
  searchProps,
  children,
  searchCalloutProps,
  commands,
  feedbackProps,
  user,
  title,
  nav,
  appNav,
  areaNav,
  backgroundColor,
  invertedBackgroundColor,
  color,
  invertedColor,
  onNavigate,
  onSignout,
  onUserChangeRequested,
  accountIconProps,
}) => {
  const { mobile } = useMediaQueries();
  const [state, setState] = React.useState({
    searching: false,
    menu: false,
    searchCallout: false,
    searchCalloutWidth: mobile ? 320 : 582,
  });
  const { i18n } = useTranslation();
  const { searching, menu } = state;
  const tc = theme ?? Theming.getTheme();
  const topBarBackgroundColor = tc.isInverted
    ? invertedBackgroundColor ?? tc.semanticColors.menuBackground
    : backgroundColor ?? tc.semanticColors.primaryButtonBackground;
  const topBarColor = tc.isInverted
    ? invertedColor ?? tc.semanticColors.bodyText
    : color ?? tc.palette.white;

  const titleItem: IButtonProps | undefined =
    title !== undefined
      ? ({
        key: "title",
        buttonStyles: {
          label: {
            fontWeight: "bold",
            whiteSpace: "nowrap",
          },
          rootHovered: {
            backgroundColor: topBarBackgroundColor,
          },
        },
        onClick: () => onNavigate("/"),
        text: typeof title === "string" ? title : undefined,
        onRenderText: typeof title === "string" ? undefined : () => title,
      } as IButtonProps)
      : undefined;
  const searchId = searchProps === undefined ? "" : useId("search-id");

  const langs: ICommandBarItemProps = {
    key: "i18n",
    iconProps: {
      iconName: "LocaleLanguage",
      styles: {
        root: {
          color: "#fff!important",
          ":hover": {
            color: `${topBarColor}!important`,
          }
        }
      }
    },
    submenuIconProps: {
      style: { display: "none" },
    },
    iconOnly: true,
    buttonStyles: {
      root: {
        backgroundColor: "transparent!important",
        ":hover": {
          backgroundColor: "transparent!important",
        }
      },
      icon: {
        color: "#fff!important",
        ":hover": {
          color: `${topBarColor}!important`,
        }
      },
      rootHovered: {
        backgroundColor: "transparent!important"
      },
      rootPressed: {
        backgroundColor: "transparent!important"
      },
      rootExpanded: {
        backgroundColor: "transparent"
      },
      menuIcon: {
        display: "none!important",
      },
    },
    subMenuProps: {
      items: [{
        key: "fr-CA",
        text: "Français",
        onClick: () => {
          i18n.changeLanguage("fr-CA")
        }
      }, {
        key: "en-US",
        text: "English",
        onClick: () => {
          i18n.changeLanguage("en-US")
        }
      }]
    }
  };

  const themePicker: ICommandBarItemProps = theme?.isInverted ?
    {
      key: "light",
      name: "Light",
      url: "#",
      iconOnly: true,
      onClick: (ev) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        trigger("onThemeChanged", {
          themeName: "light"
        })
      },
      iconProps: {
        iconName: "Light"
      }
    } :
    {
      key: "dark",
      name: "Dark",
      url: "#",
      iconOnly: true,
      onClick: (ev) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        trigger("onThemeChanged", {
          themeName: "dark"
        })
      },
      iconProps: {
        iconName: "ClearNight"
      }
    };

  const me: ICommandBarItemProps = {
    key: "me",
    onRenderIcon: () => (
      <Stack horizontal tokens={{ childrenGap: 4 }} verticalAlign="center">
        {!mobile && user !== undefined && (
          <Stack>
            <Text
              styles={{
                root: {
                  color: topBarColor,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "13px",
                  lineHeight: "normal",
                  maxWidth: "216px",
                  textAlign: "left",
                },
              }}
            >
              {IdentityUser.emailOf(user)}
            </Text>
            <Text
              styles={{
                root: {
                  color: topBarColor,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "10px",
                  lineHeight: "normal",
                  maxWidth: "166px",
                  textAlign: "right",
                  textTransform: "uppercase",
                },
              }}
            >
              {IdentityUser.nameOf(user)}
            </Text>
          </Stack>
        )}
        <Icon
          iconName={accountIconProps?.iconName ?? "Contact"}
          styles={{
            root: {
              fontSize: "24px",
              color: `${topBarColor}!important`,
            },
          }}
        />
      </Stack>
    ),
    style: {
      backgroundColor: topBarBackgroundColor,
      color: topBarColor,
    },
    submenuIconProps: {
      style: { display: "none" },
    },
    iconOnly: true,
    buttonStyles: {
      menuIcon: {
        display: "none!important",
      },
    },
    subMenuProps: {
      calloutProps: {
        calloutMaxHeight: 420,
        calloutWidth: 283,
      },
      items: [
        {
          key: "me",
          onRender: () => (
            <UserPanel
              theme={theme}
              onUserChangeRequested={onUserChangeRequested}
              onSignout={onSignout}
              user={user}
            />
          ),
        },
      ],
    },
  };

  const [onFeedback, setOnFeedback] = React.useState<boolean | undefined>(
    undefined,
  );

  const feedback: ICommandBarItemProps | undefined =
    metaExists("WWW-feedback-api") || (window as any)["WWW-feedback-api"] !== undefined
      ? {
        key: "feedback",
        ...feedbackProps,
        iconOnly: true,
        iconProps: {
          iconName: "Feedback",
          styles: {
            root: {
              fontSize: "16px",
              color: `${topBarColor}!important`,
            },
          },
        },
        style: {
          backgroundColor: topBarBackgroundColor,
          color: topBarColor,
        },
        submenuIconProps: {
          style: { display: "none" },
        },
        buttonStyles: {
          menuIcon: {
            display: "none!important",
          },
        },
        onClick: (ev) => {
          ev?.preventDefault();
          ev?.stopPropagation();
          setOnFeedback(true);
          return false;
        },
      }
      : undefined;

  return (
    <>
      {onFeedback !== undefined && (
        <div></div>
      )}
      {(appNav !== undefined || nav !== undefined) && menu ? (
        <NavigationPanel
          theme={theme}
          globalMenuOnly={globalMenuOnly}
          onDismiss={() =>
            setState({
              ...state,
              searching: false,
              menu: false,
            })
          }
          title={title}
          appNav={appNav}
          areaNav={areaNav}
          nav={nav}
          onNavigate={(value) => {
            setState({
              ...state,
              searching: false,
              menu: false,
            });
            onNavigate(value);
          }}
        />
      ) : (
        <></>
      )}
      {searching && searchProps != null ? (
        <MobileSearch
          searchProps={searchProps}
          backgroundColor={backgroundColor}
          invertedBackgroundColor={invertedBackgroundColor}
          color={color}
          invertedColor={invertedColor}
          onDismiss={() =>
            setState({
              ...state,
              searching: false,
              menu: false,
            })
          }
        />
      ) : (
        <Stack
          theme={theme}
          horizontal
          verticalAlign="center"
          styles={{
            root: {
              height: "100%",
              backgroundColor: topBarBackgroundColor,
              color: topBarColor,
              "(max-width: 767px)": searching
                ? {
                  display: "none",
                }
                : undefined,
            },
          }}
          grow
        >
          <StackItem theme={theme} grow>
            <Stack theme={theme} horizontal grow horizontalAlign="start">
              {(appNav !== undefined || nav !== undefined) && (
                <Icon
                  iconName="GlobalNavButton"
                  onClick={() =>
                    setState({
                      ...state,
                      searching: false,
                      menu: !menu,
                    })
                  }
                  styles={{
                    root: {
                      backgroundColor: topBarBackgroundColor,
                      color: topBarColor,
                      margin: "8px 10px 5px 10px",
                      fontSize: "18px",
                      cursor: "pointer",
                    },
                  }}
                />
              )}
              {titleItem !== undefined ? (
                <Stack horizontal verticalFill verticalAlign="center">
                  <CommandBarButton
                    {...titleItem}
                    styles={{
                      root: {
                        height: "33px",
                        backgroundColor: topBarBackgroundColor,
                        color: topBarColor,
                        whiteSpace: "nowrap",
                        minWidth: "100px",
                        paddingRight: 0,
                        paddingLeft: 0,
                      },
                      label: {
                        fontSize: "16px",
                        fontWeight: "bold",
                      },
                    }}
                  />
                </Stack>
              ) : (
                <></>
              )}
            </Stack>
          </StackItem>
          {!mobile && searchProps && (
            <StackItem
              grow
              align="center"
              styles={{
                root: {
                  ".ms-SearchBox": {
                    borderRadius: "4px",
                  },
                  ".ms-SearchBox::after": {
                    borderRadius: "4px",
                  },
                },
              }}
            >
              <SearchBox
                id={searchId}
                data-dashlane-rid="0"
                autoComplete="off"
                {...searchProps}
                onFocus={
                  searchCalloutProps === undefined
                    ? undefined
                    : () =>
                      setState({
                        ...state,
                        searchCallout: true,
                        searchCalloutWidth:
                          (document.getElementById(searchId)?.parentElement
                            ?.clientWidth ?? state.searchCalloutWidth + 20) -
                          20,
                      })
                }
                onBlur={
                  searchCalloutProps === undefined
                    ? undefined
                    : () =>
                      setState({
                        ...state,
                        searchCallout: false,
                      })
                }
                theme={theme}
              />
              {searchCalloutProps !== undefined && state.searchCallout && (
                <Callout
                  theme={theme}
                  target={`#${searchId}`}
                  isBeakVisible={false}
                  styles={{
                    root: {
                      marginRight: "28px",
                    },
                    calloutMain: {
                      marginTop: "5px",
                      backgroundColor: tc.isInverted
                        ? theme?.palette.black
                        : theme?.palette.white,
                    },
                  }}
                  calloutWidth={state.searchCalloutWidth}
                  directionalHint={DirectionalHint.bottomLeftEdge}
                  {...searchCalloutProps}
                >
                  {typeof searchCalloutProps.children === "function"
                    ? (searchCalloutProps.children as any)?.()
                    : searchCalloutProps.children}
                </Callout>
              )}
            </StackItem>
          )}
          {!mobile && searchProps === undefined && children !== undefined && (
            <StackItem grow>{children}</StackItem>
          )}
          <StackItem grow>
            <Stack horizontal grow horizontalAlign="end">
              {mobile && searchProps && (
                <CommandBar
                  styles={{ root: { paddingRight: "1px", borderBottom: 0 } }}
                  onReduceData={() => undefined}
                  items={[
                    {
                      key: "search",
                      iconProps: {
                        iconName: "Search",
                        styles: {
                          root: {
                            color: topBarColor,
                          },
                        },
                      },
                      buttonStyles: {
                        root: {
                          backgroundColor: topBarBackgroundColor,
                          color: topBarColor,
                        },
                      },
                      onClick: () =>
                        setState({
                          ...state,
                          searching: true,
                          menu: false,
                        }),
                    },
                  ]}
                />
              )}
              {mobile && commands != null && commands.length > 2 ? (
                <>
                  <CommandBar
                    styles={{
                      root: {
                        paddingRight: "1px",
                        borderBottom: "0px",
                      },
                    }}
                    onReduceData={() => undefined}
                    items={[
                      {
                        key: "commands",
                        iconProps: {
                          iconName: "More",
                          styles: {
                            root: {
                              color: topBarColor,
                            },
                          },
                        },
                        buttonStyles: {
                          root: {
                            backgroundColor: topBarBackgroundColor,
                            color: topBarColor,
                          },
                        },
                        subMenuProps: {
                          items: commands
                            .filter((_x, i) => i < commands.length - 1)
                            .map((x: ICommandBarItemProps) => {
                              return {
                                key: x.key,
                                iconProps: x.iconProps,
                                text: x.text,
                              };
                            }),
                        },
                      },
                    ]}
                  />
                  <CommandBar
                    key={0}
                    onReduceData={() => undefined}
                    items={[commands[commands.length - 1]]}
                    styles={{
                      root: {
                        backgroundColor: topBarBackgroundColor,
                        color: topBarColor,
                        paddingRight: "1px",
                        paddingLeft: "1px",
                        borderBottom: "0px",
                      },
                    }}
                  />
                </>
              ) : commands != null ? (
                commands.map((x: ICommandBarItemProps, index: number) => (
                  <CommandBar
                    onReduceData={() => undefined}
                    key={index}
                    items={[x]}
                    styles={{
                      root: {
                        backgroundColor: topBarBackgroundColor,
                        color: topBarColor,
                        paddingRight: "1px",
                        paddingLeft: "1px",
                        borderBottom: "0px",
                      },
                    }}
                  />
                ))
              ) : (
                <></>
              )}
              {user != null ? (
                <CommandBar
                  onReduceData={() => undefined}
                  styles={{
                    root: {
                      paddingRight: "1px",
                      paddingLeft: 0,
                      borderBottom: 0,
                      backgroundColor: "transparent",
                      ":hover": {
                        color: `${topBarColor}!important`,
                        backgroundColor: "transparent",
                      },
                      ":active": {
                        color: `${topBarColor}!important`,
                        backgroundColor: "transparent",
                      },
                      ":focus": {
                        color: `${topBarColor}!important`,
                        backgroundColor: "transparent",
                      },
                    },
                  }}
                  items={[themePicker, langs, feedback, me].filter(x => x !== undefined) as any}
                />
              ) : undefined}
            </Stack>
          </StackItem>
        </Stack>
      )}
    </>
  );
};
