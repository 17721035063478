import { DefaultPalette, Facepile, Link, Separator, Stack, Text, TooltipHost } from "@fluentui/react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { TFunction } from "i18next";
import { User } from "oidc-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { meta } from "./components/utils";

interface ILandingPageProps {
    user: User;
    t?: TFunction;
}

const defaults: IApplicationItem[] = [
    {
        id: "7fc97b5e-2754-40ef-a764-094772e2b724",
        name: "Plateforme de signalement de vulnérabilité",
        description: "",
        clientId: "7fc97b5e-2754-40ef-a764-094772e2b724",
        url: "https://www.cyber.gouv.qc.ca/signalements/signalement-vulnerabilites/formulaire",
        marketingUrl: "https://www.cyber.gouv.qc.ca/",
        privacyStatementUrl: "https://www.cyber.gouv.qc.ca/politique-confidentialite",
        supportUrl: "mailto:cgcd@mcn.gouv.qc.ca",
        termsOfServiceUrl: "https://www.cyber.gouv.qc.ca/politique-confidentialite",
        image: "https://cdn-riq-ti.azureedge.net/Malware_dark.jpg",
    }
];

const images = [
    "MNOPbe4XmxU",
    "afH9fjiom0jKr45",
    "WzKi0E3ZFRcVrl",
    "GfmCr42xCLtk8",
    "DfcbnWSjh1U7"
];

const thumbnails = [
    "https://cdn-riq-ti.azureedge.net/Malware_dark.jpg",
    "https://cdn-riq-ti.azureedge.net/Hacker1_light.jpg"
]

const appImages = {
    "5899f593-6192-4096-9901-48535df9421f": "https://www.amarinfotech.com/wp-content/uploads/2018/03/Typo3-Web-design-Get-Best-intellectual-Design.jpg", /* typo3 admin */
    "cc04d013-d3b5-4845-85a4-aaebeface115": "https://cdn.iconscout.com/icon/free/png-256/free-amazon-s3-2968702-2464706.png", /* www-serverless */
    "d701d8a1-7323-4817-bc29-12b678a88db3": "https://yogosha.com/_content/Attack-Surface-Management-Illustration-1000x561.webp", /* sia */
    "e925303a-d047-42aa-9726-c88f0313dd21": "https://i.ytimg.com/vi/xjOfL_mMWk4/hqdefault.jpg", /* wiki.js */
    "e9c6ca29-24c9-4c6e-a807-b07ede84c5c5": "https://i.ytimg.com/vi/xjOfL_mMWk4/hqdefault.jpg", /* wiki.js */
    "f62ceb36-e2d9-4463-a3cc-14951f301a2d": "https://archive.org/download/github.com-OpenCTI-Platform-opencti_-_2019-07-02_21-36-18/cover.jpg" /* OpenCTI */
} as any;


interface IApplicationItem {
    id: string;
    name: string;
    description: string;
    clientId: string;
    url: string;
    marketingUrl?: string;
    privacyStatementUrl?: string;
    supportUrl?: string;
    termsOfServiceUrl?: string;
    image?: string;
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getEngagementTimeSeconds() {
    return (Date.now() - window.firstActiveTimestamp) / 1000;
}

const getGreetings = (user: User, t: TFunction) => {
    const data = [
        [0, 4, t("Landing.Greetings.Night", "Good night")],
        [5, 11, t("Landing.Greetings.Morning", "Good morning")],
        [12, 17, t("Landing.Greetings.Afternoon", "Good afternoon")],
        [18, 24, t("Landing.Greetings.Evening", "Good evening")]
    ];
    const hr = new Date().getHours();
    for (let i = 0; i < data.length; i++) {
        if (hr >= (data[i][0] as number) && hr <= (data[i][1] as number)) {
            return `${data[i][2]}, ${user.profile.given_name ?? user.profile.name ?? user.profile.email}`;
        }
    }
    return "";
}


const LandingPageTracking = () => {
    const appInsights = useAppInsightsContext();
    const [engagementTime] = React.useState(() => getEngagementTimeSeconds());
    const metricData = {
        average: engagementTime,
        name: "React Component Engaged Time (seconds)",
        sampleCount: 1
    };
    const additionalProperties = { "Component Name": 'HomePage' };
    appInsights.trackMetric(metricData, additionalProperties);

    return (
        <></>
    );
}

export const LandingPage: React.FunctionComponent<ILandingPageProps> = (
    { user, t = useTranslation().t }
) => {
    const [items] = React.useState(() => {
        const role = user.profile.role as any;
        if (role === undefined || role == null || role === "" || role === "User") {
            return defaults;
        }
        const home = meta("oidc-client-id");
        const results = JSON.parse(window.atob(role)) as IApplicationItem[];
        return defaults.concat(results
            ?.filter(x => x.clientId != home && x.clientId != "bdff6e64-5110-42ee-bb75-6f808195ac87")
            ?.map(x => ({
                ...x,
                image: appImages[x.id] ?? thumbnails[getRandomInt(0, 1)],
            }) ?? []));
    });
    return <div style={{ width: "100%" }}>
        <div style={{
            position: "relative", height: "310px",
            backgroundImage: `url('/images/${images[getRandomInt(0, 4)]}.jpg')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100vw"
        }}>
            <div style={{
                backgroundColor: "#fff",
                position: "absolute",
                top: "45px",
                left: "45px",
                width: "280px",
                color: "#000",
                padding: "10px",
                borderRadius: "6px",
                boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"
            }}>
                <img src="/images/logo_gov.svg" style={{ height: "18px" }} />
                <h1 style={{ marginTop: "1px" }}>{t("Application.Title", "Centre gouvernemental de cyberdéfense")}</h1>
                <h3>{getGreetings(user, t)}</h3>
            </div>
        </div>
        {items.map((x: any) => <Stack key={x.id} styles={{
            root: {
                margin: "20px",
                float: "left",
                border: `1px solid ${DefaultPalette.neutralLight}`,
                boxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px",
                borderRadius: "6px",
                minHeight: "213px",
                width: "210px",
                ":hover": {
                    boxShadow: "rgb(255, 255, 255) 0px 0px 3px, rgba(0, 0, 0, 0.3) 0px 3.2px 7.2px",
                },
                ":hover .FeaturedArticleImage": {
                    backgroundPosition: "-20px -20px",
                    backgroundSize: "auto 115%",
                }
            }
        }}>
            <Stack className="FeaturedArticleBox" styles={{
                root: {
                    ".FeaturedArticleImage": {
                        backgroundColor: "rgb(27, 26, 25)",
                        backgroundImage: `url(${x.image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "auto 100%",
                        backgroundPosition: "0px 0px",
                        borderRadius: "10px 10px 0px 0px",
                        height: "195px",
                        margin: "0px",
                        transition: "background-size 0.2s ease-in 0s, background-position 0.2s ease-in 0s",
                        width: "100%",
                    }
                }
            }}>
                <div className="FeaturedArticleImageWrapper">
                    <div className="FeaturedArticleImage"></div></div>
                <div className="FeaturedArticleContent" style={{
                    padding: "10px",
                    minHeight: "200px"
                }}>
                    <TooltipHost content={<div style={{
                        padding: "10px"
                    }}>
                        <Text>{(x.description ?? "") != "" ? x.description : x.name}</Text>
                    </div>}>
                        <div className="FeaturedLabel">⦿ Application</div>
                    </TooltipHost>
                    <Separator />
                    <h3 data-qa="featured-title" className="FeaturedTitle" style={{
                        margin: "0px"
                    }}>
                        <Link data-qa="featured-title-link" className="Link FeaturedTitleLink" href={x.url} target="_blank">
                            {x.name}
                        </Link>
                    </h3>
                    {(x.marketingUrl ?? "") !== "" && x.marketingUrl !== x.url && <><Separator />
                        <Link href={x.marketingUrl}>
                            Plus d'info
                        </Link>
                    </>}
                    {(x.supportUrl ?? "") !== "" && <><Separator />
                        <Link href={x.supportUrl}>
                            Support
                        </Link>
                    </>}
                    <Separator />
                    <div data-qa="metaWrapper" className="MetaWrapper">
                        <div className="MetaData">
                            {(x.owner?.length ?? 0) > 0 && <Facepile personas={[{
                                personaName: "CGCD"
                            }]} />}
                        </div></div></div>
            </Stack>
        </Stack>)}
        <div style={{ clear: "both" }}></div>
        <LandingPageTracking />
    </div>
};