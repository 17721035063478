import { ITheme } from "@fluentui/react";
import {
  Theming,
} from "./components/Theming";
import i18next from "i18next";
import * as React from "react";
import { TFunction } from "i18next";
import { authService } from "./components/AuthService";
import { ISplashScreenContainerProps, SplashScreenContainer } from "./components/SplashScreen/SplashScreenContainer";
import { Client } from "@microsoft/microsoft-graph-client";

const themingEnabled = true;
const getLatestTheme = (userTheme: string, authTime?: number) => {
  const theme = localStorage.getItem("theme");
  const themeTime = localStorage.getItem("theme_h");
  return themeTime == null || theme == null || authTime === undefined
    ? userTheme
    : parseInt(themeTime) > authTime
      ? theme ?? userTheme
      : userTheme;
};

interface IAuthenticatedProps {
  theme: ITheme;
  onThemeChanged: (theme: string) => void;
  isSignup?: boolean;
  children?: any;
}

export class Authenticated extends React.Component<IAuthenticatedProps, any> {
  private shouldCancel: boolean;

  constructor(props: any) {
    super(props);
    this.state = { api: {} };
    this.shouldCancel = false;
  }

  public componentDidMount() {
    if (document.location.pathname !== "/signout-oidc") {
      this.getUser();
    }
  }

  public login = () => {
    if (document.location.pathname !== "/signout-oidc") {
      authService.cleanUp().then(() => {
        authService.userManager.clearStaleState();
        authService.login();
      });
    }
  };

  public componentWillUnmount() {
    this.shouldCancel = true;
  }

  public renewToken = () => {

    authService
      .renewToken()
      .then(() => {
        this.getUser();
      })
      .catch(this.login);
  };

  public logout = () => {

    authService.logout();
  };

  public getUser = () => {
    authService
      .getUser()
      .then((user) => {
        let triggered = false;
        if (user) {
          if ((user.expires_in ?? 0) > 0 && (user.expires_in ?? 0) < 25) {
            this.renewToken();
            triggered = true;
          } else if ((user.expires_in ?? 0) < 0) {
            this.login();
            triggered = true;
          } else {
            authService.userManager.startSilentRenew();
          }
        } else {
          this.login();
          triggered = true;
        }
        if (!this.shouldCancel) {
          const role = user?.profile.role as any;
          if (role === undefined || role == null || role === "" || role === "User") {
            if (!(user?.profile.email ?? "").includes("mcn.gouv.qc.ca") && !triggered) {
              document.location.href = "https://www.cyber.gouv.qc.ca/";
            }
            return;
          }
          else {
            if (!triggered) {
              const results = JSON.parse(window.atob(role)) as any[];
              if (!(user?.profile.email ?? "").includes("mcn.gouv.qc.ca") && results.length === 0) {
                document.location.href = "https://www.cyber.gouv.qc.ca/";
                return;
              }
            }
          }
          const userTheme = themingEnabled
            ? getLatestTheme((user?.profile as any)?.theme, user?.profile.auth_time)
            : "";
          const currentTheme = Theming.getTheme().isInverted ? "dark" : "";
          if (userTheme !== undefined && userTheme !== currentTheme) {
            localStorage.setItem("theme", userTheme);
            localStorage.setItem("theme_t", new Date().getTime().toString());
            this.props.onThemeChanged(userTheme);
            document.body.style.backgroundColor =
              Theming.getTheme().semanticColors.bodyBackground;
          }
          window.user = user ?? undefined;
          this.forceUpdate();
        }
      })
      .catch(this.login);
  };

  public render() {
    const { children } = this.props;
    const { t } = i18next;
    const { user } = window;

    return (
      <>
        {user !== undefined && user != null && (user.expires_in ?? 0) < 25 ? (
          typeof children === "function" ? (
            children(user, authService, Client.init({
              authProvider: (done) => {
                user.access_token && done(undefined, user.access_token)
              }
            }))
          ) : (
            children
          )
        ) : (
          <SplashScreenContainer {...this.splashScreenProps(this.props, t)} />
        )}
      </>
    );
  }

  private splashScreenProps = (
    props: IAuthenticatedProps,
    t: TFunction,
  ): ISplashScreenContainerProps => ({
    ...props,
    text: t("SplashScreen.Text", "Administation Center"),
    secondaryText: t("SplashScreen.SecondaryText", "Authenticating"),
    tertiaryText: t("SplashScreen.TertiaryText", "Please wait..."),
  });
}
