import React from "react";
import { AppContainer } from "./AppContainer";
import { AppRouter } from "./AppRouter";
import { Theming } from "./components/Theming";
import { useEvent } from "./components/useEvent";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_APPINSIGHT ?? "",
        enableAutoRouteTracking: true,
        extensions: [reactPlugin as any]
    }
});
appInsights.loadAppInsights();

const defaultTheme = Theming.getTheme();

export interface IAppProps {
    ver?: string;
}

export const AppComponent: React.FunctionComponent<IAppProps> = () => {
    const [state, setState] = React.useState({
        theme: defaultTheme,
    });

    const theme = state.theme ?? defaultTheme;

    useEvent("onThemeChanged", ({ themeName }) => {
        localStorage.setItem("theme", themeName);
        setState({
            theme: Theming.getTheme(),
        });
    });

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <AppContainer theme={theme}>
                <AppRouter
                    theme={theme}
                    onThemeChanged={(themeName) => {
                        localStorage.setItem("theme", themeName);
                        setState({
                            theme: Theming.getTheme(),
                        });
                    }}
                />
            </AppContainer>
        </AppInsightsContext.Provider>
    );
};

export const App = withAITracking(reactPlugin, AppComponent);