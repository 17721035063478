import { Link, Stack, Text } from "@fluentui/react";
import * as React from "react";
import { MediaQueries } from "./components/MediaQueries";
import { authService } from "./components/AuthService";

export interface IError401Props {
  children?: any;
}

export const Error401: React.FunctionComponent<IError401Props> = ({
  children,
}) => {
  React.useEffect(() => {
    authService.cleanUp();
  }, []);
  return (
    <Stack
      title={"Accès refusé"}
      grow
      verticalFill
      styles={{
        root: {
          marginTop: "60px",
          height: "calc(-44px + 100vh)",
          [MediaQueries.mobile]: {
            marginLeft: "10px",
          },
        },
      }}
      verticalAlign="center"
      horizontalAlign="center"
    >
      <div>
        <Stack tokens={{ childrenGap: 10 }} verticalFill>
          <Text
            variant="xxLargePlus"
            styles={{
              root: {
                fontSize: "84px",
                [MediaQueries.tablet]: {
                  fontSize: "72px",
                },
                [MediaQueries.mobile]: {
                  fontSize: "56px",
                  marginLeft: "5px",
                },
              },
            }}
          >
            :(
          </Text>
          <Text variant="xLarge">
            Vous n'avez pas accès au Réseau gouvernemental de cyberdéfense.
          </Text>
          <Text variant="xLarge">
            Contactez votre administrateur d'organisme.
          </Text>
          <Link href="#" onClick={() => authService.logout()}>Se déconnecter</Link>
        </Stack>
      </div>
      {children}
    </Stack>
  );
};
