import { initializeIcons } from "@fluentui/react";
import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { App } from "./App";
import { initializeIntl } from "./components/i18n";
import { LocaleHost } from "./components/LocaleHost";
window.firstActiveTimestamp = Date.now();
initializeIcons();
const container = document.getElementById("root");
if (container == null) throw new Error("no_root");
const root = ReactDOMClient.createRoot(container);

initializeIntl(["default"],
  localStorage.getItem("mkt") ?? "fr-CA").then(() => {
    root.render(
      <React.Suspense fallback={<div></div>}>
        <LocaleHost>
          <App />
        </LocaleHost>
      </React.Suspense>,
    )
  });