import {
  IComponentAs,
  Icon,
  IPersonaCoinProps,
  IPersonaProps,
  ITheme,
  Persona,
  PersonaSize,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { SplashScreenStyles } from "./SplashScreen.styles";

export interface ISplashScreenProps {
  text: string;
  theme?: ITheme;
  as?: IComponentAs<any>;
  coinProps?: IPersonaCoinProps;
  personaProps?: IPersonaProps;
  footerSrc?: string;
  children?: any;
}

const isSignout = (): boolean => location.pathname === "/signout-callback-oidc";

export const SplashScreen: React.FunctionComponent<ISplashScreenProps> = ({
  coinProps,
  personaProps,
  theme,
  text,
  as,
  children,
  footerSrc,
}) => {
  const TextAs = as as any;
  const { t } = useTranslation();
  return (
    <div style={SplashScreenStyles.container}>
      {children !== undefined ? (
        children
      ) : (
        <div style={SplashScreenStyles.logo}>
          <Persona
            theme={theme}
            size={PersonaSize.size120}
            coinProps={
              coinProps ?? {
                onRenderInitials: () => (
                  <Icon
                    theme={theme}
                    iconName="IDBadge"
                    styles={SplashScreenStyles.icon}
                  />
                ),
              }
            }
            {...personaProps}
          />
        </div>
      )}
      {isSignout() ? (
        <h1 style={SplashScreenStyles.wait}>
          <>{t("SplashScreen.SigningOut", "Signing out...")}</>
        </h1>
      ) : as != null ? (
        <TextAs key="text">{text}</TextAs>
      ) : (
        <h3 style={SplashScreenStyles.wait}>{text}</h3>
      )}
      <Spinner
        theme={theme}
        size={SpinnerSize.large}
        styles={SplashScreenStyles.spinner}
      />
      <img src={footerSrc ?? "/images/logo.png"} style={SplashScreenStyles.footer} />
    </div>
  );
};
