
import { WebStorageStateStore, UserManagerSettings } from "oidc-client-ts";
import { meta, ServiceUris } from "./utils";

const extraParams = (): any | undefined => {
  const locale = localStorage.getItem("mkt") ?? "fr-CA";
  return { locale }
};

export const OidcSettings: UserManagerSettings = {
  automaticSilentRenew: true,
  loadUserInfo: true,
  monitorSession: false,
  authority: ServiceUris.authority(),
  client_id: meta("oidc-client-id"),
  redirect_uri: `${location.origin}/signin-callback.html`,
  silent_redirect_uri: `${location.origin}/silent-renew.html`,
  response_type: "code",
  scope: "openid offline_access",
  includeIdTokenInSilentRenew: true,
  post_logout_redirect_uri: `${location.origin}/signout-callback-oidc`,
  userStore: new WebStorageStateStore({ store: sessionStorage }),
  stateStore: new WebStorageStateStore({
    store: sessionStorage,
  }),
  ui_locales: "fr",
  extraQueryParams: extraParams(),
  extraTokenParams: extraParams(),
};
